const data = {
  'v': '5.4.1',
  'fr': 29.9700012207031,
  'ip': 0,
  'op': 75.0000030548126,
  'w': 1280,
  'h': 800,
  'nm': 'Confetti',
  'ddd': 0,
  'assets': [
    {
      'id': 'comp_0',
      'layers': [
        {
          'ddd': 0,
          'ind': 1,
          'ty': 4,
          'nm': 'elems Outlines',
          'sr': 1,
          'ks': {
            'o': {
              'a': 0,
              'k': 100,
              'ix': 11
            },
            'r': {
              'a': 0,
              'k': 0,
              'ix': 10
            },
            'p': {
              'a': 0,
              'k': [
                648,
                408,
                0
              ],
              'ix': 2
            },
            'a': {
              'a': 0,
              'k': [
                640,
                400,
                0
              ],
              'ix': 1
            },
            's': {
              'a': 0,
              'k': [
                55,
                55,
                100
              ],
              'ix': 6
            }
          },
          'ao': 0,
          'shapes': [
            {
              'ty': 'gr',
              'it': [
                {
                  'ind': 0,
                  'ty': 'sh',
                  'ix': 1,
                  'ks': {
                    'a': 0,
                    'k': {
                      'i': [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'o': [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'v': [
                        [
                          0,
                          -56.517
                        ],
                        [
                          65.259,
                          56.517
                        ],
                        [
                          -65.26,
                          56.517
                        ]
                      ],
                      'c': true
                    },
                    'ix': 2
                  },
                  'nm': 'Path 1',
                  'mn': 'ADBE Vector Shape - Group',
                  'hd': false
                },
                {
                  'ty': 'st',
                  'c': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.833
                          ],
                          'y': [
                            0.833
                          ]
                        },
                        'o': {
                          'x': [
                            0.167
                          ],
                          'y': [
                            0.167
                          ]
                        },
                        'n': [
                          '0p833_0p833_0p167_0p167'
                        ],
                        't': 0,
                        's': [
                          0.96862745098,
                          0.423529411765,
                          0.533333333333,
                          1
                        ],
                        'e': [
                          0.701960802078,
                          0.070588238537,
                          0.090196080506,
                          1
                        ]
                      },
                      {
                        't': 45.0000018328876
                      }
                    ],
                    'ix': 3
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 4
                  },
                  'w': {
                    'a': 0,
                    'k': 25,
                    'ix': 5
                  },
                  'lc': 1,
                  'lj': 1,
                  'ml': 10,
                  'ml2': {
                    'a': 0,
                    'k': 10,
                    'ix': 8
                  },
                  'nm': 'Stroke 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  'hd': false
                },
                {
                  'ty': 'tr',
                  'p': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': 0.196,
                          'y': 1
                        },
                        'o': {
                          'x': 0,
                          'y': 0
                        },
                        'n': '0p196_1_0_0',
                        't': 0,
                        's': [
                          632.987,
                          391.08
                        ],
                        'e': [
                          285.686,
                          74.119
                        ],
                        'to': [
                          -57.8834648132324,
                          -52.8268241882324
                        ],
                        'ti': [
                          57.8834648132324,
                          52.8268241882324
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 2
                  },
                  'a': {
                    'a': 0,
                    'k': [
                      0,
                      18
                    ],
                    'ix': 1
                  },
                  's': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.833,
                            0.833
                          ],
                          'y': [
                            0.833,
                            0.833
                          ]
                        },
                        'o': {
                          'x': [
                            1,
                            1
                          ],
                          'y': [
                            0,
                            0
                          ]
                        },
                        'n': [
                          '0p833_0p833_1_0',
                          '0p833_0p833_1_0'
                        ],
                        't': 0,
                        's': [
                          33.246,
                          33.246
                        ],
                        'e': [
                          0,
                          0
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 3
                  },
                  'r': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.529
                          ],
                          'y': [
                            1
                          ]
                        },
                        'o': {
                          'x': [
                            0.054
                          ],
                          'y': [
                            0.039
                          ]
                        },
                        'n': [
                          '0p529_1_0p054_0p039'
                        ],
                        't': 0,
                        's': [
                          0
                        ],
                        'e': [
                          720
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 6
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 7
                  },
                  'sk': {
                    'a': 0,
                    'k': 0,
                    'ix': 4
                  },
                  'sa': {
                    'a': 0,
                    'k': 0,
                    'ix': 5
                  },
                  'nm': 'Transform'
                }
              ],
              'nm': 'Group 1',
              'np': 2,
              'cix': 2,
              'ix': 1,
              'mn': 'ADBE Vector Group',
              'hd': false
            },
            {
              'ty': 'gr',
              'it': [
                {
                  'ind': 0,
                  'ty': 'sh',
                  'ix': 1,
                  'ks': {
                    'a': 0,
                    'k': {
                      'i': [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'o': [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'v': [
                        [
                          -78.593,
                          0
                        ],
                        [
                          0.001,
                          -78.593
                        ],
                        [
                          78.594,
                          0
                        ],
                        [
                          0.001,
                          78.593
                        ]
                      ],
                      'c': true
                    },
                    'ix': 2
                  },
                  'nm': 'Path 1',
                  'mn': 'ADBE Vector Shape - Group',
                  'hd': false
                },
                {
                  'ty': 'st',
                  'c': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.833
                          ],
                          'y': [
                            0.833
                          ]
                        },
                        'o': {
                          'x': [
                            0.167
                          ],
                          'y': [
                            0.167
                          ]
                        },
                        'n': [
                          '0p833_0p833_0p167_0p167'
                        ],
                        't': 0,
                        's': [
                          0.643137276173,
                          0.35686275363,
                          0.945098042488,
                          1
                        ],
                        'e': [
                          0.61960786581,
                          0.243137255311,
                          0.996078431606,
                          1
                        ]
                      },
                      {
                        't': 45.0000018328876
                      }
                    ],
                    'ix': 3
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 4
                  },
                  'w': {
                    'a': 0,
                    'k': 25,
                    'ix': 5
                  },
                  'lc': 1,
                  'lj': 1,
                  'ml': 10,
                  'ml2': {
                    'a': 0,
                    'k': 10,
                    'ix': 8
                  },
                  'nm': 'Stroke 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  'hd': false
                },
                {
                  'ty': 'tr',
                  'p': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': 0.196,
                          'y': 1
                        },
                        'o': {
                          'x': 0,
                          'y': 0
                        },
                        'n': '0p196_1_0_0',
                        't': 0,
                        's': [
                          632.987,
                          391.08
                        ],
                        'e': [
                          629.686,
                          746.119
                        ],
                        'to': [
                          -0.55013018846512,
                          59.1731758117676
                        ],
                        'ti': [
                          0.55013018846512,
                          -59.1731758117676
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 2
                  },
                  'a': {
                    'a': 0,
                    'k': [
                      0,
                      0
                    ],
                    'ix': 1
                  },
                  's': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.833,
                            0.833
                          ],
                          'y': [
                            0.833,
                            0.833
                          ]
                        },
                        'o': {
                          'x': [
                            1,
                            1
                          ],
                          'y': [
                            0,
                            0
                          ]
                        },
                        'n': [
                          '0p833_0p833_1_0',
                          '0p833_0p833_1_0'
                        ],
                        't': 0,
                        's': [
                          33.246,
                          33.246
                        ],
                        'e': [
                          0,
                          0
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 3
                  },
                  'r': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.529
                          ],
                          'y': [
                            1
                          ]
                        },
                        'o': {
                          'x': [
                            0.054
                          ],
                          'y': [
                            0.039
                          ]
                        },
                        'n': [
                          '0p529_1_0p054_0p039'
                        ],
                        't': 0,
                        's': [
                          0
                        ],
                        'e': [
                          720
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 6
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 7
                  },
                  'sk': {
                    'a': 0,
                    'k': 0,
                    'ix': 4
                  },
                  'sa': {
                    'a': 0,
                    'k': 0,
                    'ix': 5
                  },
                  'nm': 'Transform'
                }
              ],
              'nm': 'Group 3',
              'np': 2,
              'cix': 2,
              'ix': 2,
              'mn': 'ADBE Vector Group',
              'hd': false
            },
            {
              'ty': 'gr',
              'it': [
                {
                  'ind': 0,
                  'ty': 'sh',
                  'ix': 1,
                  'ks': {
                    'a': 0,
                    'k': {
                      'i': [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'o': [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'v': [
                        [
                          24.5,
                          -23.5
                        ],
                        [
                          24.5,
                          -81.5
                        ],
                        [
                          -23.5,
                          -81.5
                        ],
                        [
                          -23.5,
                          -23.5
                        ],
                        [
                          -81.5,
                          -23.5
                        ],
                        [
                          -81.5,
                          23.5
                        ],
                        [
                          -23.5,
                          23.5
                        ],
                        [
                          -23.5,
                          81.5
                        ],
                        [
                          24.5,
                          81.5
                        ],
                        [
                          24.5,
                          23.5
                        ],
                        [
                          81.5,
                          23.5
                        ],
                        [
                          81.5,
                          -23.5
                        ]
                      ],
                      'c': true
                    },
                    'ix': 2
                  },
                  'nm': 'Path 1',
                  'mn': 'ADBE Vector Shape - Group',
                  'hd': false
                },
                {
                  'ty': 'st',
                  'c': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.833
                          ],
                          'y': [
                            0.833
                          ]
                        },
                        'o': {
                          'x': [
                            0.167
                          ],
                          'y': [
                            0.167
                          ]
                        },
                        'n': [
                          '0p833_0p833_0p167_0p167'
                        ],
                        't': 0,
                        's': [
                          0.960784313725,
                          0.96862745098,
                          0.439215686275,
                          1
                        ],
                        'e': [
                          0.96928614378,
                          0.919638156891,
                          0.541258990765,
                          1
                        ]
                      },
                      {
                        't': 45.0000018328876
                      }
                    ],
                    'ix': 3
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 4
                  },
                  'w': {
                    'a': 0,
                    'k': 25,
                    'ix': 5
                  },
                  'lc': 1,
                  'lj': 1,
                  'ml': 10,
                  'ml2': {
                    'a': 0,
                    'k': 10,
                    'ix': 8
                  },
                  'nm': 'Stroke 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  'hd': false
                },
                {
                  'ty': 'tr',
                  'p': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': 0.196,
                          'y': 1
                        },
                        'o': {
                          'x': 0,
                          'y': 0
                        },
                        'n': '0p196_1_0_0',
                        't': 0,
                        's': [
                          632.987,
                          391.08
                        ],
                        'e': [
                          1013.686,
                          133.119
                        ],
                        'to': [
                          63.4498710632324,
                          -42.9934883117676
                        ],
                        'ti': [
                          -63.4498710632324,
                          42.9934883117676
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 2
                  },
                  'a': {
                    'a': 0,
                    'k': [
                      0,
                      0
                    ],
                    'ix': 1
                  },
                  's': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.833,
                            0.833
                          ],
                          'y': [
                            0.833,
                            0.833
                          ]
                        },
                        'o': {
                          'x': [
                            1,
                            1
                          ],
                          'y': [
                            0,
                            0
                          ]
                        },
                        'n': [
                          '0p833_0p833_1_0',
                          '0p833_0p833_1_0'
                        ],
                        't': 0,
                        's': [
                          33.246,
                          33.246
                        ],
                        'e': [
                          0,
                          0
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 3
                  },
                  'r': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.529
                          ],
                          'y': [
                            1
                          ]
                        },
                        'o': {
                          'x': [
                            0.054
                          ],
                          'y': [
                            0.039
                          ]
                        },
                        'n': [
                          '0p529_1_0p054_0p039'
                        ],
                        't': 0,
                        's': [
                          0
                        ],
                        'e': [
                          720
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 6
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 7
                  },
                  'sk': {
                    'a': 0,
                    'k': 0,
                    'ix': 4
                  },
                  'sa': {
                    'a': 0,
                    'k': 0,
                    'ix': 5
                  },
                  'nm': 'Transform'
                }
              ],
              'nm': 'Group 5',
              'np': 2,
              'cix': 2,
              'ix': 3,
              'mn': 'ADBE Vector Group',
              'hd': false
            },
            {
              'ty': 'gr',
              'it': [
                {
                  'ind': 0,
                  'ty': 'sh',
                  'ix': 1,
                  'ks': {
                    'a': 0,
                    'k': {
                      'i': [
                        [
                          -43.406,
                          0
                        ],
                        [
                          0,
                          -43.406
                        ],
                        [
                          43.406,
                          0
                        ],
                        [
                          0,
                          43.406
                        ]
                      ],
                      'o': [
                        [
                          43.406,
                          0
                        ],
                        [
                          0,
                          43.406
                        ],
                        [
                          -43.406,
                          0
                        ],
                        [
                          0,
                          -43.406
                        ]
                      ],
                      'v': [
                        [
                          0,
                          -78.593
                        ],
                        [
                          78.593,
                          0
                        ],
                        [
                          0,
                          78.593
                        ],
                        [
                          -78.593,
                          0
                        ]
                      ],
                      'c': true
                    },
                    'ix': 2
                  },
                  'nm': 'Path 1',
                  'mn': 'ADBE Vector Shape - Group',
                  'hd': false
                },
                {
                  'ty': 'st',
                  'c': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.833
                          ],
                          'y': [
                            0.833
                          ]
                        },
                        'o': {
                          'x': [
                            0.167
                          ],
                          'y': [
                            0.167
                          ]
                        },
                        'n': [
                          '0p833_0p833_0p167_0p167'
                        ],
                        't': 0,
                        's': [
                          0.145098039216,
                          0.776470588235,
                          0.964705882353,
                          1
                        ],
                        'e': [
                          0.196078434587,
                          0.745098054409,
                          0.996078431606,
                          1
                        ]
                      },
                      {
                        't': 45.0000018328876
                      }
                    ],
                    'ix': 3
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 4
                  },
                  'w': {
                    'a': 0,
                    'k': 25,
                    'ix': 5
                  },
                  'lc': 1,
                  'lj': 1,
                  'ml': 10,
                  'ml2': {
                    'a': 0,
                    'k': 10,
                    'ix': 8
                  },
                  'nm': 'Stroke 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  'hd': false
                },
                {
                  'ty': 'tr',
                  'p': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': 0.196,
                          'y': 1
                        },
                        'o': {
                          'x': 0,
                          'y': 0
                        },
                        'n': '0p196_1_0_0',
                        't': 0,
                        's': [
                          632.987,
                          391.08
                        ],
                        'e': [
                          137.686,
                          622.119
                        ],
                        'to': [
                          -82.5501327514648,
                          38.5065116882324
                        ],
                        'ti': [
                          82.5501327514648,
                          -38.5065116882324
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 2
                  },
                  'a': {
                    'a': 0,
                    'k': [
                      0,
                      0
                    ],
                    'ix': 1
                  },
                  's': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.833,
                            0.833
                          ],
                          'y': [
                            0.833,
                            0.833
                          ]
                        },
                        'o': {
                          'x': [
                            1,
                            1
                          ],
                          'y': [
                            0,
                            0
                          ]
                        },
                        'n': [
                          '0p833_0p833_1_0',
                          '0p833_0p833_1_0'
                        ],
                        't': 0,
                        's': [
                          33.246,
                          33.246
                        ],
                        'e': [
                          0,
                          0
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 3
                  },
                  'r': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.529
                          ],
                          'y': [
                            1
                          ]
                        },
                        'o': {
                          'x': [
                            0.054
                          ],
                          'y': [
                            0.039
                          ]
                        },
                        'n': [
                          '0p529_1_0p054_0p039'
                        ],
                        't': 0,
                        's': [
                          0
                        ],
                        'e': [
                          720
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 6
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 7
                  },
                  'sk': {
                    'a': 0,
                    'k': 0,
                    'ix': 4
                  },
                  'sa': {
                    'a': 0,
                    'k': 0,
                    'ix': 5
                  },
                  'nm': 'Transform'
                }
              ],
              'nm': 'Group 7',
              'np': 2,
              'cix': 2,
              'ix': 4,
              'mn': 'ADBE Vector Group',
              'hd': false
            }
          ],
          'ip': 0,
          'op': 300.00001221925,
          'st': 0,
          'bm': 0
        },
        {
          'ddd': 0,
          'ind': 2,
          'ty': 4,
          'nm': 'elems greys',
          'sr': 1,
          'ks': {
            'o': {
              'a': 0,
              'k': 50,
              'ix': 11
            },
            'r': {
              'a': 0,
              'k': 0,
              'ix': 10
            },
            'p': {
              'a': 0,
              'k': [
                653,
                411,
                0
              ],
              'ix': 2
            },
            'a': {
              'a': 0,
              'k': [
                640,
                400,
                0
              ],
              'ix': 1
            },
            's': {
              'a': 0,
              'k': [
                55,
                55,
                100
              ],
              'ix': 6
            }
          },
          'ao': 0,
          'shapes': [
            {
              'ty': 'gr',
              'it': [
                {
                  'ind': 0,
                  'ty': 'sh',
                  'ix': 1,
                  'ks': {
                    'a': 0,
                    'k': {
                      'i': [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'o': [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'v': [
                        [
                          0,
                          -56.517
                        ],
                        [
                          65.259,
                          56.517
                        ],
                        [
                          -65.26,
                          56.517
                        ]
                      ],
                      'c': true
                    },
                    'ix': 2
                  },
                  'nm': 'Path 1',
                  'mn': 'ADBE Vector Shape - Group',
                  'hd': false
                },
                {
                  'ty': 'st',
                  'c': {
                    'a': 0,
                    'k': [
                      0.903109669685,
                      0.903109669685,
                      0.903109669685,
                      1
                    ],
                    'ix': 3
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 4
                  },
                  'w': {
                    'a': 0,
                    'k': 25,
                    'ix': 5
                  },
                  'lc': 1,
                  'lj': 1,
                  'ml': 10,
                  'ml2': {
                    'a': 0,
                    'k': 10,
                    'ix': 8
                  },
                  'nm': 'Stroke 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  'hd': false
                },
                {
                  'ty': 'tr',
                  'p': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': 0.196,
                          'y': 1
                        },
                        'o': {
                          'x': 0,
                          'y': 0
                        },
                        'n': '0p196_1_0_0',
                        't': 0,
                        's': [
                          632.987,
                          391.08
                        ],
                        'e': [
                          277.686,
                          123.119
                        ],
                        'to': [
                          -59.216796875,
                          -44.66015625
                        ],
                        'ti': [
                          59.216796875,
                          44.66015625
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 2
                  },
                  'a': {
                    'a': 0,
                    'k': [
                      0,
                      18
                    ],
                    'ix': 1
                  },
                  's': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.833,
                            0.833
                          ],
                          'y': [
                            0.833,
                            0.833
                          ]
                        },
                        'o': {
                          'x': [
                            1,
                            1
                          ],
                          'y': [
                            0,
                            0
                          ]
                        },
                        'n': [
                          '0p833_0p833_1_0',
                          '0p833_0p833_1_0'
                        ],
                        't': 0,
                        's': [
                          33.246,
                          33.246
                        ],
                        'e': [
                          0,
                          0
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 3
                  },
                  'r': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.529
                          ],
                          'y': [
                            1
                          ]
                        },
                        'o': {
                          'x': [
                            0.054
                          ],
                          'y': [
                            0.039
                          ]
                        },
                        'n': [
                          '0p529_1_0p054_0p039'
                        ],
                        't': 0,
                        's': [
                          0
                        ],
                        'e': [
                          720
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 6
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 7
                  },
                  'sk': {
                    'a': 0,
                    'k': 0,
                    'ix': 4
                  },
                  'sa': {
                    'a': 0,
                    'k': 0,
                    'ix': 5
                  },
                  'nm': 'Transform'
                }
              ],
              'nm': 'Group 1',
              'np': 2,
              'cix': 2,
              'ix': 1,
              'mn': 'ADBE Vector Group',
              'hd': false
            },
            {
              'ty': 'gr',
              'it': [
                {
                  'ind': 0,
                  'ty': 'sh',
                  'ix': 1,
                  'ks': {
                    'a': 0,
                    'k': {
                      'i': [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'o': [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'v': [
                        [
                          -78.593,
                          0
                        ],
                        [
                          0.001,
                          -78.593
                        ],
                        [
                          78.594,
                          0
                        ],
                        [
                          0.001,
                          78.593
                        ]
                      ],
                      'c': true
                    },
                    'ix': 2
                  },
                  'nm': 'Path 1',
                  'mn': 'ADBE Vector Shape - Group',
                  'hd': false
                },
                {
                  'ty': 'st',
                  'c': {
                    'a': 0,
                    'k': [
                      0.901960790157,
                      0.901960790157,
                      0.901960790157,
                      1
                    ],
                    'ix': 3
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 4
                  },
                  'w': {
                    'a': 0,
                    'k': 25,
                    'ix': 5
                  },
                  'lc': 1,
                  'lj': 1,
                  'ml': 10,
                  'ml2': {
                    'a': 0,
                    'k': 10,
                    'ix': 8
                  },
                  'nm': 'Stroke 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  'hd': false
                },
                {
                  'ty': 'tr',
                  'p': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': 0.196,
                          'y': 1
                        },
                        'o': {
                          'x': 0,
                          'y': 0
                        },
                        'n': '0p196_1_0_0',
                        't': 0,
                        's': [
                          632.987,
                          391.08
                        ],
                        'e': [
                          648.686,
                          706.119
                        ],
                        'to': [
                          2.61653637886047,
                          52.5065116882324
                        ],
                        'ti': [
                          -2.61653637886047,
                          -52.5065116882324
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 2
                  },
                  'a': {
                    'a': 0,
                    'k': [
                      0,
                      0
                    ],
                    'ix': 1
                  },
                  's': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.833,
                            0.833
                          ],
                          'y': [
                            0.833,
                            0.833
                          ]
                        },
                        'o': {
                          'x': [
                            1,
                            1
                          ],
                          'y': [
                            0,
                            0
                          ]
                        },
                        'n': [
                          '0p833_0p833_1_0',
                          '0p833_0p833_1_0'
                        ],
                        't': 0,
                        's': [
                          33.246,
                          33.246
                        ],
                        'e': [
                          0,
                          0
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 3
                  },
                  'r': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.529
                          ],
                          'y': [
                            1
                          ]
                        },
                        'o': {
                          'x': [
                            0.054
                          ],
                          'y': [
                            0.039
                          ]
                        },
                        'n': [
                          '0p529_1_0p054_0p039'
                        ],
                        't': 0,
                        's': [
                          0
                        ],
                        'e': [
                          720
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 6
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 7
                  },
                  'sk': {
                    'a': 0,
                    'k': 0,
                    'ix': 4
                  },
                  'sa': {
                    'a': 0,
                    'k': 0,
                    'ix': 5
                  },
                  'nm': 'Transform'
                }
              ],
              'nm': 'Group 3',
              'np': 2,
              'cix': 2,
              'ix': 2,
              'mn': 'ADBE Vector Group',
              'hd': false
            },
            {
              'ty': 'gr',
              'it': [
                {
                  'ind': 0,
                  'ty': 'sh',
                  'ix': 1,
                  'ks': {
                    'a': 0,
                    'k': {
                      'i': [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'o': [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'v': [
                        [
                          24.5,
                          -23.5
                        ],
                        [
                          24.5,
                          -81.5
                        ],
                        [
                          -23.5,
                          -81.5
                        ],
                        [
                          -23.5,
                          -23.5
                        ],
                        [
                          -81.5,
                          -23.5
                        ],
                        [
                          -81.5,
                          23.5
                        ],
                        [
                          -23.5,
                          23.5
                        ],
                        [
                          -23.5,
                          81.5
                        ],
                        [
                          24.5,
                          81.5
                        ],
                        [
                          24.5,
                          23.5
                        ],
                        [
                          81.5,
                          23.5
                        ],
                        [
                          81.5,
                          -23.5
                        ]
                      ],
                      'c': true
                    },
                    'ix': 2
                  },
                  'nm': 'Path 1',
                  'mn': 'ADBE Vector Shape - Group',
                  'hd': false
                },
                {
                  'ty': 'st',
                  'c': {
                    'a': 0,
                    'k': [
                      0.901960790157,
                      0.901960790157,
                      0.901960790157,
                      1
                    ],
                    'ix': 3
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 4
                  },
                  'w': {
                    'a': 0,
                    'k': 25,
                    'ix': 5
                  },
                  'lc': 1,
                  'lj': 1,
                  'ml': 10,
                  'ml2': {
                    'a': 0,
                    'k': 10,
                    'ix': 8
                  },
                  'nm': 'Stroke 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  'hd': false
                },
                {
                  'ty': 'tr',
                  'p': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': 0.196,
                          'y': 1
                        },
                        'o': {
                          'x': 0,
                          'y': 0
                        },
                        'n': '0p196_1_0_0',
                        't': 0,
                        's': [
                          632.987,
                          391.08
                        ],
                        'e': [
                          992.686,
                          193.119
                        ],
                        'to': [
                          59.9498710632324,
                          -32.9934883117676
                        ],
                        'ti': [
                          -59.9498710632324,
                          32.9934883117676
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 2
                  },
                  'a': {
                    'a': 0,
                    'k': [
                      0,
                      0
                    ],
                    'ix': 1
                  },
                  's': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.833,
                            0.833
                          ],
                          'y': [
                            0.833,
                            0.833
                          ]
                        },
                        'o': {
                          'x': [
                            1,
                            1
                          ],
                          'y': [
                            0,
                            0
                          ]
                        },
                        'n': [
                          '0p833_0p833_1_0',
                          '0p833_0p833_1_0'
                        ],
                        't': 0,
                        's': [
                          33.246,
                          33.246
                        ],
                        'e': [
                          0,
                          0
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 3
                  },
                  'r': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.529
                          ],
                          'y': [
                            1
                          ]
                        },
                        'o': {
                          'x': [
                            0.054
                          ],
                          'y': [
                            0.039
                          ]
                        },
                        'n': [
                          '0p529_1_0p054_0p039'
                        ],
                        't': 0,
                        's': [
                          0
                        ],
                        'e': [
                          720
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 6
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 7
                  },
                  'sk': {
                    'a': 0,
                    'k': 0,
                    'ix': 4
                  },
                  'sa': {
                    'a': 0,
                    'k': 0,
                    'ix': 5
                  },
                  'nm': 'Transform'
                }
              ],
              'nm': 'Group 5',
              'np': 2,
              'cix': 2,
              'ix': 3,
              'mn': 'ADBE Vector Group',
              'hd': false
            },
            {
              'ty': 'gr',
              'it': [
                {
                  'ind': 0,
                  'ty': 'sh',
                  'ix': 1,
                  'ks': {
                    'a': 0,
                    'k': {
                      'i': [
                        [
                          -43.406,
                          0
                        ],
                        [
                          0,
                          -43.406
                        ],
                        [
                          43.406,
                          0
                        ],
                        [
                          0,
                          43.406
                        ]
                      ],
                      'o': [
                        [
                          43.406,
                          0
                        ],
                        [
                          0,
                          43.406
                        ],
                        [
                          -43.406,
                          0
                        ],
                        [
                          0,
                          -43.406
                        ]
                      ],
                      'v': [
                        [
                          0,
                          -78.593
                        ],
                        [
                          78.593,
                          0
                        ],
                        [
                          0,
                          78.593
                        ],
                        [
                          -78.593,
                          0
                        ]
                      ],
                      'c': true
                    },
                    'ix': 2
                  },
                  'nm': 'Path 1',
                  'mn': 'ADBE Vector Shape - Group',
                  'hd': false
                },
                {
                  'ty': 'st',
                  'c': {
                    'a': 0,
                    'k': [
                      0.901960790157,
                      0.901960790157,
                      0.901960790157,
                      1
                    ],
                    'ix': 3
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 4
                  },
                  'w': {
                    'a': 0,
                    'k': 25,
                    'ix': 5
                  },
                  'lc': 1,
                  'lj': 1,
                  'ml': 10,
                  'ml2': {
                    'a': 0,
                    'k': 10,
                    'ix': 8
                  },
                  'nm': 'Stroke 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  'hd': false
                },
                {
                  'ty': 'tr',
                  'p': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': 0.196,
                          'y': 1
                        },
                        'o': {
                          'x': 0,
                          'y': 0
                        },
                        'n': '0p196_1_0_0',
                        't': 0,
                        's': [
                          632.987,
                          391.08
                        ],
                        'e': [
                          171.686,
                          618.119
                        ],
                        'to': [
                          -76.8834609985352,
                          37.83984375
                        ],
                        'ti': [
                          76.8834609985352,
                          -37.83984375
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 2
                  },
                  'a': {
                    'a': 0,
                    'k': [
                      0,
                      0
                    ],
                    'ix': 1
                  },
                  's': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.833,
                            0.833
                          ],
                          'y': [
                            0.833,
                            0.833
                          ]
                        },
                        'o': {
                          'x': [
                            1,
                            1
                          ],
                          'y': [
                            0,
                            0
                          ]
                        },
                        'n': [
                          '0p833_0p833_1_0',
                          '0p833_0p833_1_0'
                        ],
                        't': 0,
                        's': [
                          33.246,
                          33.246
                        ],
                        'e': [
                          0,
                          0
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 3
                  },
                  'r': {
                    'a': 1,
                    'k': [
                      {
                        'i': {
                          'x': [
                            0.529
                          ],
                          'y': [
                            1
                          ]
                        },
                        'o': {
                          'x': [
                            0.054
                          ],
                          'y': [
                            0.039
                          ]
                        },
                        'n': [
                          '0p529_1_0p054_0p039'
                        ],
                        't': 0,
                        's': [
                          0
                        ],
                        'e': [
                          720
                        ]
                      },
                      {
                        't': 60.0000024438501
                      }
                    ],
                    'ix': 6
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 7
                  },
                  'sk': {
                    'a': 0,
                    'k': 0,
                    'ix': 4
                  },
                  'sa': {
                    'a': 0,
                    'k': 0,
                    'ix': 5
                  },
                  'nm': 'Transform'
                }
              ],
              'nm': 'Group 7',
              'np': 2,
              'cix': 2,
              'ix': 4,
              'mn': 'ADBE Vector Group',
              'hd': false
            }
          ],
          'ip': 0,
          'op': 300.00001221925,
          'st': 0,
          'bm': 0
        }
      ]
    },
    {
      'id': 'comp_1',
      'layers': [
        {
          'ddd': 0,
          'ind': 1,
          'ty': 4,
          'nm': 'twiggle Outlines 10',
          'sr': 1,
          'ks': {
            'o': {
              'a': 0,
              'k': 100,
              'ix': 11
            },
            'r': {
              'a': 0,
              'k': 20,
              'ix': 10
            },
            'p': {
              'a': 0,
              'k': [
                640,
                399,
                0
              ],
              'ix': 2
            },
            'a': {
              'a': 0,
              'k': [
                545,
                457,
                0
              ],
              'ix': 1
            },
            's': {
              'a': 0,
              'k': [
                74.124,
                74.124,
                100
              ],
              'ix': 6
            }
          },
          'ao': 0,
          'shapes': [
            {
              'ty': 'gr',
              'it': [
                {
                  'ind': 0,
                  'ty': 'sh',
                  'ix': 1,
                  'ks': {
                    'a': 0,
                    'k': {
                      'i': [
                        [
                          0,
                          0
                        ],
                        [
                          -54.167,
                          -22.916
                        ],
                        [
                          -31.25,
                          25
                        ],
                        [
                          -17.709,
                          23.959
                        ],
                        [
                          -17.709,
                          37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'o': [
                        [
                          0,
                          0
                        ],
                        [
                          54.166,
                          22.917
                        ],
                        [
                          31.25,
                          -25
                        ],
                        [
                          17.708,
                          -23.958
                        ],
                        [
                          17.708,
                          -37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'v': [
                        [
                          -141.771,
                          120.599
                        ],
                        [
                          -86.354,
                          90.057
                        ],
                        [
                          -33.229,
                          21.307
                        ],
                        [
                          48.021,
                          -3.693
                        ],
                        [
                          60.281,
                          -83.099
                        ],
                        [
                          141.771,
                          -118.996
                        ]
                      ],
                      'c': false
                    },
                    'ix': 2
                  },
                  'nm': 'Path 1',
                  'mn': 'ADBE Vector Shape - Group',
                  'hd': false
                },
                {
                  'ty': 'st',
                  'c': {
                    'a': 0,
                    'k': [
                      0.901960790157,
                      0.901960790157,
                      0.901960790157,
                      1
                    ],
                    'ix': 3
                  },
                  'o': {
                    'a': 0,
                    'k': 50,
                    'ix': 4
                  },
                  'w': {
                    'a': 0,
                    'k': 8,
                    'ix': 5
                  },
                  'lc': 1,
                  'lj': 1,
                  'ml': 10,
                  'ml2': {
                    'a': 0,
                    'k': 10,
                    'ix': 8
                  },
                  'nm': 'Stroke 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  'hd': false
                },
                {
                  'ty': 'fl',
                  'c': {
                    'a': 0,
                    'k': [
                      1,
                      1,
                      1,
                      1
                    ],
                    'ix': 4
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 5
                  },
                  'r': 1,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  'hd': false
                },
                {
                  'ty': 'tr',
                  'p': {
                    'a': 0,
                    'k': [
                      788.771,
                      279.401
                    ],
                    'ix': 2
                  },
                  'a': {
                    'a': 0,
                    'k': [
                      0,
                      0
                    ],
                    'ix': 1
                  },
                  's': {
                    'a': 0,
                    'k': [
                      100,
                      100
                    ],
                    'ix': 3
                  },
                  'r': {
                    'a': 0,
                    'k': 0,
                    'ix': 6
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 7
                  },
                  'sk': {
                    'a': 0,
                    'k': 0,
                    'ix': 4
                  },
                  'sa': {
                    'a': 0,
                    'k': 0,
                    'ix': 5
                  },
                  'nm': 'Transform'
                }
              ],
              'nm': 'Group 1',
              'np': 3,
              'cix': 2,
              'ix': 1,
              'mn': 'ADBE Vector Group',
              'hd': false
            },
            {
              'ty': 'tm',
              's': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 25,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 38.0000015477717
                  }
                ],
                'ix': 1
              },
              'e': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 12,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 38.0000015477717
                  }
                ],
                'ix': 2
              },
              'o': {
                'a': 0,
                'k': 0,
                'ix': 3
              },
              'm': 1,
              'ix': 2,
              'nm': 'Trim Paths 1',
              'mn': 'ADBE Vector Filter - Trim',
              'hd': false
            }
          ],
          'ip': 0,
          'op': 300.00001221925,
          'st': 0,
          'bm': 0
        },
        {
          'ddd': 0,
          'ind': 2,
          'ty': 4,
          'nm': 'twiggle Outlines 9',
          'sr': 1,
          'ks': {
            'o': {
              'a': 0,
              'k': 100,
              'ix': 11
            },
            'r': {
              'a': 0,
              'k': 308,
              'ix': 10
            },
            'p': {
              'a': 0,
              'k': [
                640,
                399,
                0
              ],
              'ix': 2
            },
            'a': {
              'a': 0,
              'k': [
                545,
                457,
                0
              ],
              'ix': 1
            },
            's': {
              'a': 0,
              'k': [
                74.124,
                74.124,
                100
              ],
              'ix': 6
            }
          },
          'ao': 0,
          'shapes': [
            {
              'ty': 'gr',
              'it': [
                {
                  'ind': 0,
                  'ty': 'sh',
                  'ix': 1,
                  'ks': {
                    'a': 0,
                    'k': {
                      'i': [
                        [
                          0,
                          0
                        ],
                        [
                          -54.167,
                          -22.916
                        ],
                        [
                          -31.25,
                          25
                        ],
                        [
                          -17.709,
                          23.959
                        ],
                        [
                          -17.709,
                          37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'o': [
                        [
                          0,
                          0
                        ],
                        [
                          54.166,
                          22.917
                        ],
                        [
                          31.25,
                          -25
                        ],
                        [
                          17.708,
                          -23.958
                        ],
                        [
                          17.708,
                          -37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'v': [
                        [
                          -141.771,
                          120.599
                        ],
                        [
                          -86.354,
                          90.057
                        ],
                        [
                          -33.229,
                          21.307
                        ],
                        [
                          48.021,
                          -3.693
                        ],
                        [
                          60.281,
                          -83.099
                        ],
                        [
                          141.771,
                          -118.996
                        ]
                      ],
                      'c': false
                    },
                    'ix': 2
                  },
                  'nm': 'Path 1',
                  'mn': 'ADBE Vector Shape - Group',
                  'hd': false
                },
                {
                  'ty': 'st',
                  'c': {
                    'a': 0,
                    'k': [
                      0.901960790157,
                      0.901960790157,
                      0.901960790157,
                      1
                    ],
                    'ix': 3
                  },
                  'o': {
                    'a': 0,
                    'k': 50,
                    'ix': 4
                  },
                  'w': {
                    'a': 0,
                    'k': 8,
                    'ix': 5
                  },
                  'lc': 1,
                  'lj': 1,
                  'ml': 10,
                  'ml2': {
                    'a': 0,
                    'k': 10,
                    'ix': 8
                  },
                  'nm': 'Stroke 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  'hd': false
                },
                {
                  'ty': 'fl',
                  'c': {
                    'a': 0,
                    'k': [
                      1,
                      1,
                      1,
                      1
                    ],
                    'ix': 4
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 5
                  },
                  'r': 1,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  'hd': false
                },
                {
                  'ty': 'tr',
                  'p': {
                    'a': 0,
                    'k': [
                      788.771,
                      279.401
                    ],
                    'ix': 2
                  },
                  'a': {
                    'a': 0,
                    'k': [
                      0,
                      0
                    ],
                    'ix': 1
                  },
                  's': {
                    'a': 0,
                    'k': [
                      100,
                      100
                    ],
                    'ix': 3
                  },
                  'r': {
                    'a': 0,
                    'k': 0,
                    'ix': 6
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 7
                  },
                  'sk': {
                    'a': 0,
                    'k': 0,
                    'ix': 4
                  },
                  'sa': {
                    'a': 0,
                    'k': 0,
                    'ix': 5
                  },
                  'nm': 'Transform'
                }
              ],
              'nm': 'Group 1',
              'np': 3,
              'cix': 2,
              'ix': 1,
              'mn': 'ADBE Vector Group',
              'hd': false
            },
            {
              'ty': 'tm',
              's': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 25,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 38.0000015477717
                  }
                ],
                'ix': 1
              },
              'e': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 12,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 38.0000015477717
                  }
                ],
                'ix': 2
              },
              'o': {
                'a': 0,
                'k': 0,
                'ix': 3
              },
              'm': 1,
              'ix': 2,
              'nm': 'Trim Paths 1',
              'mn': 'ADBE Vector Filter - Trim',
              'hd': false
            }
          ],
          'ip': 0,
          'op': 300.00001221925,
          'st': 0,
          'bm': 0
        },
        {
          'ddd': 0,
          'ind': 3,
          'ty': 4,
          'nm': 'twiggle Outlines 8',
          'sr': 1,
          'ks': {
            'o': {
              'a': 0,
              'k': 100,
              'ix': 11
            },
            'r': {
              'a': 0,
              'k': 236,
              'ix': 10
            },
            'p': {
              'a': 0,
              'k': [
                640,
                399,
                0
              ],
              'ix': 2
            },
            'a': {
              'a': 0,
              'k': [
                545,
                457,
                0
              ],
              'ix': 1
            },
            's': {
              'a': 0,
              'k': [
                74.124,
                74.124,
                100
              ],
              'ix': 6
            }
          },
          'ao': 0,
          'shapes': [
            {
              'ty': 'gr',
              'it': [
                {
                  'ind': 0,
                  'ty': 'sh',
                  'ix': 1,
                  'ks': {
                    'a': 0,
                    'k': {
                      'i': [
                        [
                          0,
                          0
                        ],
                        [
                          -54.167,
                          -22.916
                        ],
                        [
                          -31.25,
                          25
                        ],
                        [
                          -17.709,
                          23.959
                        ],
                        [
                          -17.709,
                          37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'o': [
                        [
                          0,
                          0
                        ],
                        [
                          54.166,
                          22.917
                        ],
                        [
                          31.25,
                          -25
                        ],
                        [
                          17.708,
                          -23.958
                        ],
                        [
                          17.708,
                          -37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'v': [
                        [
                          -141.771,
                          120.599
                        ],
                        [
                          -86.354,
                          90.057
                        ],
                        [
                          -33.229,
                          21.307
                        ],
                        [
                          48.021,
                          -3.693
                        ],
                        [
                          60.281,
                          -83.099
                        ],
                        [
                          141.771,
                          -118.996
                        ]
                      ],
                      'c': false
                    },
                    'ix': 2
                  },
                  'nm': 'Path 1',
                  'mn': 'ADBE Vector Shape - Group',
                  'hd': false
                },
                {
                  'ty': 'st',
                  'c': {
                    'a': 0,
                    'k': [
                      0.901960790157,
                      0.901960790157,
                      0.901960790157,
                      1
                    ],
                    'ix': 3
                  },
                  'o': {
                    'a': 0,
                    'k': 50,
                    'ix': 4
                  },
                  'w': {
                    'a': 0,
                    'k': 8,
                    'ix': 5
                  },
                  'lc': 1,
                  'lj': 1,
                  'ml': 10,
                  'ml2': {
                    'a': 0,
                    'k': 10,
                    'ix': 8
                  },
                  'nm': 'Stroke 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  'hd': false
                },
                {
                  'ty': 'fl',
                  'c': {
                    'a': 0,
                    'k': [
                      1,
                      1,
                      1,
                      1
                    ],
                    'ix': 4
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 5
                  },
                  'r': 1,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  'hd': false
                },
                {
                  'ty': 'tr',
                  'p': {
                    'a': 0,
                    'k': [
                      788.771,
                      279.401
                    ],
                    'ix': 2
                  },
                  'a': {
                    'a': 0,
                    'k': [
                      0,
                      0
                    ],
                    'ix': 1
                  },
                  's': {
                    'a': 0,
                    'k': [
                      100,
                      100
                    ],
                    'ix': 3
                  },
                  'r': {
                    'a': 0,
                    'k': 0,
                    'ix': 6
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 7
                  },
                  'sk': {
                    'a': 0,
                    'k': 0,
                    'ix': 4
                  },
                  'sa': {
                    'a': 0,
                    'k': 0,
                    'ix': 5
                  },
                  'nm': 'Transform'
                }
              ],
              'nm': 'Group 1',
              'np': 3,
              'cix': 2,
              'ix': 1,
              'mn': 'ADBE Vector Group',
              'hd': false
            },
            {
              'ty': 'tm',
              's': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 25,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 38.0000015477717
                  }
                ],
                'ix': 1
              },
              'e': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 12,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 38.0000015477717
                  }
                ],
                'ix': 2
              },
              'o': {
                'a': 0,
                'k': 0,
                'ix': 3
              },
              'm': 1,
              'ix': 2,
              'nm': 'Trim Paths 1',
              'mn': 'ADBE Vector Filter - Trim',
              'hd': false
            }
          ],
          'ip': 0,
          'op': 300.00001221925,
          'st': 0,
          'bm': 0
        },
        {
          'ddd': 0,
          'ind': 4,
          'ty': 4,
          'nm': 'twiggle Outlines 7',
          'sr': 1,
          'ks': {
            'o': {
              'a': 0,
              'k': 100,
              'ix': 11
            },
            'r': {
              'a': 0,
              'k': 164,
              'ix': 10
            },
            'p': {
              'a': 0,
              'k': [
                640,
                399,
                0
              ],
              'ix': 2
            },
            'a': {
              'a': 0,
              'k': [
                545,
                457,
                0
              ],
              'ix': 1
            },
            's': {
              'a': 0,
              'k': [
                74.124,
                74.124,
                100
              ],
              'ix': 6
            }
          },
          'ao': 0,
          'shapes': [
            {
              'ty': 'gr',
              'it': [
                {
                  'ind': 0,
                  'ty': 'sh',
                  'ix': 1,
                  'ks': {
                    'a': 0,
                    'k': {
                      'i': [
                        [
                          0,
                          0
                        ],
                        [
                          -54.167,
                          -22.916
                        ],
                        [
                          -31.25,
                          25
                        ],
                        [
                          -17.709,
                          23.959
                        ],
                        [
                          -17.709,
                          37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'o': [
                        [
                          0,
                          0
                        ],
                        [
                          54.166,
                          22.917
                        ],
                        [
                          31.25,
                          -25
                        ],
                        [
                          17.708,
                          -23.958
                        ],
                        [
                          17.708,
                          -37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'v': [
                        [
                          -141.771,
                          120.599
                        ],
                        [
                          -86.354,
                          90.057
                        ],
                        [
                          -33.229,
                          21.307
                        ],
                        [
                          48.021,
                          -3.693
                        ],
                        [
                          60.281,
                          -83.099
                        ],
                        [
                          141.771,
                          -118.996
                        ]
                      ],
                      'c': false
                    },
                    'ix': 2
                  },
                  'nm': 'Path 1',
                  'mn': 'ADBE Vector Shape - Group',
                  'hd': false
                },
                {
                  'ty': 'st',
                  'c': {
                    'a': 0,
                    'k': [
                      0.901960790157,
                      0.901960790157,
                      0.901960790157,
                      1
                    ],
                    'ix': 3
                  },
                  'o': {
                    'a': 0,
                    'k': 50,
                    'ix': 4
                  },
                  'w': {
                    'a': 0,
                    'k': 8,
                    'ix': 5
                  },
                  'lc': 1,
                  'lj': 1,
                  'ml': 10,
                  'ml2': {
                    'a': 0,
                    'k': 10,
                    'ix': 8
                  },
                  'nm': 'Stroke 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  'hd': false
                },
                {
                  'ty': 'fl',
                  'c': {
                    'a': 0,
                    'k': [
                      1,
                      1,
                      1,
                      1
                    ],
                    'ix': 4
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 5
                  },
                  'r': 1,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  'hd': false
                },
                {
                  'ty': 'tr',
                  'p': {
                    'a': 0,
                    'k': [
                      788.771,
                      279.401
                    ],
                    'ix': 2
                  },
                  'a': {
                    'a': 0,
                    'k': [
                      0,
                      0
                    ],
                    'ix': 1
                  },
                  's': {
                    'a': 0,
                    'k': [
                      100,
                      100
                    ],
                    'ix': 3
                  },
                  'r': {
                    'a': 0,
                    'k': 0,
                    'ix': 6
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 7
                  },
                  'sk': {
                    'a': 0,
                    'k': 0,
                    'ix': 4
                  },
                  'sa': {
                    'a': 0,
                    'k': 0,
                    'ix': 5
                  },
                  'nm': 'Transform'
                }
              ],
              'nm': 'Group 1',
              'np': 3,
              'cix': 2,
              'ix': 1,
              'mn': 'ADBE Vector Group',
              'hd': false
            },
            {
              'ty': 'tm',
              's': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 25,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 38.0000015477717
                  }
                ],
                'ix': 1
              },
              'e': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 12,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 38.0000015477717
                  }
                ],
                'ix': 2
              },
              'o': {
                'a': 0,
                'k': 0,
                'ix': 3
              },
              'm': 1,
              'ix': 2,
              'nm': 'Trim Paths 1',
              'mn': 'ADBE Vector Filter - Trim',
              'hd': false
            }
          ],
          'ip': 0,
          'op': 300.00001221925,
          'st': 0,
          'bm': 0
        },
        {
          'ddd': 0,
          'ind': 5,
          'ty': 4,
          'nm': 'twiggle Outlines 6',
          'sr': 1,
          'ks': {
            'o': {
              'a': 0,
              'k': 100,
              'ix': 11
            },
            'r': {
              'a': 0,
              'k': 92,
              'ix': 10
            },
            'p': {
              'a': 0,
              'k': [
                640,
                399,
                0
              ],
              'ix': 2
            },
            'a': {
              'a': 0,
              'k': [
                545,
                457,
                0
              ],
              'ix': 1
            },
            's': {
              'a': 0,
              'k': [
                74.124,
                74.124,
                100
              ],
              'ix': 6
            }
          },
          'ao': 0,
          'shapes': [
            {
              'ty': 'gr',
              'it': [
                {
                  'ind': 0,
                  'ty': 'sh',
                  'ix': 1,
                  'ks': {
                    'a': 0,
                    'k': {
                      'i': [
                        [
                          0,
                          0
                        ],
                        [
                          -54.167,
                          -22.916
                        ],
                        [
                          -31.25,
                          25
                        ],
                        [
                          -17.709,
                          23.959
                        ],
                        [
                          -17.709,
                          37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'o': [
                        [
                          0,
                          0
                        ],
                        [
                          54.166,
                          22.917
                        ],
                        [
                          31.25,
                          -25
                        ],
                        [
                          17.708,
                          -23.958
                        ],
                        [
                          17.708,
                          -37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'v': [
                        [
                          -141.771,
                          120.599
                        ],
                        [
                          -86.354,
                          90.057
                        ],
                        [
                          -33.229,
                          21.307
                        ],
                        [
                          48.021,
                          -3.693
                        ],
                        [
                          60.281,
                          -83.099
                        ],
                        [
                          141.771,
                          -118.996
                        ]
                      ],
                      'c': false
                    },
                    'ix': 2
                  },
                  'nm': 'Path 1',
                  'mn': 'ADBE Vector Shape - Group',
                  'hd': false
                },
                {
                  'ty': 'st',
                  'c': {
                    'a': 0,
                    'k': [
                      0.901960790157,
                      0.901960790157,
                      0.901960790157,
                      1
                    ],
                    'ix': 3
                  },
                  'o': {
                    'a': 0,
                    'k': 50,
                    'ix': 4
                  },
                  'w': {
                    'a': 0,
                    'k': 8,
                    'ix': 5
                  },
                  'lc': 1,
                  'lj': 1,
                  'ml': 10,
                  'ml2': {
                    'a': 0,
                    'k': 10,
                    'ix': 8
                  },
                  'nm': 'Stroke 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  'hd': false
                },
                {
                  'ty': 'fl',
                  'c': {
                    'a': 0,
                    'k': [
                      1,
                      1,
                      1,
                      1
                    ],
                    'ix': 4
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 5
                  },
                  'r': 1,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  'hd': false
                },
                {
                  'ty': 'tr',
                  'p': {
                    'a': 0,
                    'k': [
                      788.771,
                      279.401
                    ],
                    'ix': 2
                  },
                  'a': {
                    'a': 0,
                    'k': [
                      0,
                      0
                    ],
                    'ix': 1
                  },
                  's': {
                    'a': 0,
                    'k': [
                      100,
                      100
                    ],
                    'ix': 3
                  },
                  'r': {
                    'a': 0,
                    'k': 0,
                    'ix': 6
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 7
                  },
                  'sk': {
                    'a': 0,
                    'k': 0,
                    'ix': 4
                  },
                  'sa': {
                    'a': 0,
                    'k': 0,
                    'ix': 5
                  },
                  'nm': 'Transform'
                }
              ],
              'nm': 'Group 1',
              'np': 3,
              'cix': 2,
              'ix': 1,
              'mn': 'ADBE Vector Group',
              'hd': false
            },
            {
              'ty': 'tm',
              's': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 25,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 38.0000015477717
                  }
                ],
                'ix': 1
              },
              'e': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 12,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 38.0000015477717
                  }
                ],
                'ix': 2
              },
              'o': {
                'a': 0,
                'k': 0,
                'ix': 3
              },
              'm': 1,
              'ix': 2,
              'nm': 'Trim Paths 1',
              'mn': 'ADBE Vector Filter - Trim',
              'hd': false
            }
          ],
          'ip': 0,
          'op': 300.00001221925,
          'st': 0,
          'bm': 0
        },
        {
          'ddd': 0,
          'ind': 6,
          'ty': 4,
          'nm': 'twiggle Outlines 5',
          'sr': 1,
          'ks': {
            'o': {
              'a': 0,
              'k': 100,
              'ix': 11
            },
            'r': {
              'a': 0,
              'k': 360,
              'ix': 10
            },
            'p': {
              'a': 0,
              'k': [
                640,
                400,
                0
              ],
              'ix': 2
            },
            'a': {
              'a': 0,
              'k': [
                640,
                400,
                0
              ],
              'ix': 1
            },
            's': {
              'a': 0,
              'k': [
                74.124,
                74.124,
                100
              ],
              'ix': 6
            }
          },
          'ao': 0,
          'shapes': [
            {
              'ty': 'gr',
              'it': [
                {
                  'ind': 0,
                  'ty': 'sh',
                  'ix': 1,
                  'ks': {
                    'a': 0,
                    'k': {
                      'i': [
                        [
                          0,
                          0
                        ],
                        [
                          -54.167,
                          -22.916
                        ],
                        [
                          -31.25,
                          25
                        ],
                        [
                          -17.709,
                          23.959
                        ],
                        [
                          -17.709,
                          37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'o': [
                        [
                          0,
                          0
                        ],
                        [
                          54.166,
                          22.917
                        ],
                        [
                          31.25,
                          -25
                        ],
                        [
                          17.708,
                          -23.958
                        ],
                        [
                          17.708,
                          -37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'v': [
                        [
                          -141.771,
                          120.599
                        ],
                        [
                          -86.354,
                          90.057
                        ],
                        [
                          -33.229,
                          21.307
                        ],
                        [
                          48.021,
                          -3.693
                        ],
                        [
                          60.281,
                          -83.099
                        ],
                        [
                          141.771,
                          -118.996
                        ]
                      ],
                      'c': false
                    },
                    'ix': 2
                  },
                  'nm': 'Path 1',
                  'mn': 'ADBE Vector Shape - Group',
                  'hd': false
                },
                {
                  'ty': 'st',
                  'c': {
                    'a': 0,
                    'k': [
                      0.96862745285,
                      0.423529416323,
                      0.533333361149,
                      1
                    ],
                    'ix': 3
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 4
                  },
                  'w': {
                    'a': 0,
                    'k': 8,
                    'ix': 5
                  },
                  'lc': 1,
                  'lj': 1,
                  'ml': 10,
                  'ml2': {
                    'a': 0,
                    'k': 10,
                    'ix': 8
                  },
                  'nm': 'Stroke 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  'hd': false
                },
                {
                  'ty': 'fl',
                  'c': {
                    'a': 0,
                    'k': [
                      1,
                      1,
                      1,
                      1
                    ],
                    'ix': 4
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 5
                  },
                  'r': 1,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  'hd': false
                },
                {
                  'ty': 'tr',
                  'p': {
                    'a': 0,
                    'k': [
                      788.771,
                      279.401
                    ],
                    'ix': 2
                  },
                  'a': {
                    'a': 0,
                    'k': [
                      0,
                      0
                    ],
                    'ix': 1
                  },
                  's': {
                    'a': 0,
                    'k': [
                      100,
                      100
                    ],
                    'ix': 3
                  },
                  'r': {
                    'a': 0,
                    'k': 0,
                    'ix': 6
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 7
                  },
                  'sk': {
                    'a': 0,
                    'k': 0,
                    'ix': 4
                  },
                  'sa': {
                    'a': 0,
                    'k': 0,
                    'ix': 5
                  },
                  'nm': 'Transform'
                }
              ],
              'nm': 'Group 1',
              'np': 3,
              'cix': 2,
              'ix': 1,
              'mn': 'ADBE Vector Group',
              'hd': false
            },
            {
              'ty': 'tm',
              's': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 13,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 30.0000012219251
                  }
                ],
                'ix': 1
              },
              'e': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 0,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 30.0000012219251
                  }
                ],
                'ix': 2
              },
              'o': {
                'a': 0,
                'k': 0,
                'ix': 3
              },
              'm': 1,
              'ix': 2,
              'nm': 'Trim Paths 1',
              'mn': 'ADBE Vector Filter - Trim',
              'hd': false
            }
          ],
          'ip': 0,
          'op': 300.00001221925,
          'st': 0,
          'bm': 0
        },
        {
          'ddd': 0,
          'ind': 7,
          'ty': 4,
          'nm': 'twiggle Outlines 4',
          'sr': 1,
          'ks': {
            'o': {
              'a': 0,
              'k': 100,
              'ix': 11
            },
            'r': {
              'a': 0,
              'k': 288,
              'ix': 10
            },
            'p': {
              'a': 0,
              'k': [
                640,
                400,
                0
              ],
              'ix': 2
            },
            'a': {
              'a': 0,
              'k': [
                640,
                400,
                0
              ],
              'ix': 1
            },
            's': {
              'a': 0,
              'k': [
                74.124,
                74.124,
                100
              ],
              'ix': 6
            }
          },
          'ao': 0,
          'shapes': [
            {
              'ty': 'gr',
              'it': [
                {
                  'ind': 0,
                  'ty': 'sh',
                  'ix': 1,
                  'ks': {
                    'a': 0,
                    'k': {
                      'i': [
                        [
                          0,
                          0
                        ],
                        [
                          -54.167,
                          -22.916
                        ],
                        [
                          -31.25,
                          25
                        ],
                        [
                          -17.709,
                          23.959
                        ],
                        [
                          -17.709,
                          37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'o': [
                        [
                          0,
                          0
                        ],
                        [
                          54.166,
                          22.917
                        ],
                        [
                          31.25,
                          -25
                        ],
                        [
                          17.708,
                          -23.958
                        ],
                        [
                          17.708,
                          -37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'v': [
                        [
                          -141.771,
                          120.599
                        ],
                        [
                          -86.354,
                          90.057
                        ],
                        [
                          -33.229,
                          21.307
                        ],
                        [
                          48.021,
                          -3.693
                        ],
                        [
                          60.281,
                          -83.099
                        ],
                        [
                          141.771,
                          -118.996
                        ]
                      ],
                      'c': false
                    },
                    'ix': 2
                  },
                  'nm': 'Path 1',
                  'mn': 'ADBE Vector Shape - Group',
                  'hd': false
                },
                {
                  'ty': 'st',
                  'c': {
                    'a': 0,
                    'k': [
                      0.643137276173,
                      0.35686275363,
                      0.945098042488,
                      1
                    ],
                    'ix': 3
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 4
                  },
                  'w': {
                    'a': 0,
                    'k': 8,
                    'ix': 5
                  },
                  'lc': 1,
                  'lj': 1,
                  'ml': 10,
                  'ml2': {
                    'a': 0,
                    'k': 10,
                    'ix': 8
                  },
                  'nm': 'Stroke 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  'hd': false
                },
                {
                  'ty': 'fl',
                  'c': {
                    'a': 0,
                    'k': [
                      1,
                      1,
                      1,
                      1
                    ],
                    'ix': 4
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 5
                  },
                  'r': 1,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  'hd': false
                },
                {
                  'ty': 'tr',
                  'p': {
                    'a': 0,
                    'k': [
                      788.771,
                      279.401
                    ],
                    'ix': 2
                  },
                  'a': {
                    'a': 0,
                    'k': [
                      0,
                      0
                    ],
                    'ix': 1
                  },
                  's': {
                    'a': 0,
                    'k': [
                      100,
                      100
                    ],
                    'ix': 3
                  },
                  'r': {
                    'a': 0,
                    'k': 0,
                    'ix': 6
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 7
                  },
                  'sk': {
                    'a': 0,
                    'k': 0,
                    'ix': 4
                  },
                  'sa': {
                    'a': 0,
                    'k': 0,
                    'ix': 5
                  },
                  'nm': 'Transform'
                }
              ],
              'nm': 'Group 1',
              'np': 3,
              'cix': 2,
              'ix': 1,
              'mn': 'ADBE Vector Group',
              'hd': false
            },
            {
              'ty': 'tm',
              's': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 13,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 30.0000012219251
                  }
                ],
                'ix': 1
              },
              'e': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 0,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 30.0000012219251
                  }
                ],
                'ix': 2
              },
              'o': {
                'a': 0,
                'k': 0,
                'ix': 3
              },
              'm': 1,
              'ix': 2,
              'nm': 'Trim Paths 1',
              'mn': 'ADBE Vector Filter - Trim',
              'hd': false
            }
          ],
          'ip': 0,
          'op': 300.00001221925,
          'st': 0,
          'bm': 0
        },
        {
          'ddd': 0,
          'ind': 8,
          'ty': 4,
          'nm': 'twiggle Outlines 3',
          'sr': 1,
          'ks': {
            'o': {
              'a': 0,
              'k': 100,
              'ix': 11
            },
            'r': {
              'a': 0,
              'k': 216,
              'ix': 10
            },
            'p': {
              'a': 0,
              'k': [
                640,
                400,
                0
              ],
              'ix': 2
            },
            'a': {
              'a': 0,
              'k': [
                640,
                400,
                0
              ],
              'ix': 1
            },
            's': {
              'a': 0,
              'k': [
                74.124,
                74.124,
                100
              ],
              'ix': 6
            }
          },
          'ao': 0,
          'shapes': [
            {
              'ty': 'gr',
              'it': [
                {
                  'ind': 0,
                  'ty': 'sh',
                  'ix': 1,
                  'ks': {
                    'a': 0,
                    'k': {
                      'i': [
                        [
                          0,
                          0
                        ],
                        [
                          -54.167,
                          -22.916
                        ],
                        [
                          -31.25,
                          25
                        ],
                        [
                          -17.709,
                          23.959
                        ],
                        [
                          -17.709,
                          37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'o': [
                        [
                          0,
                          0
                        ],
                        [
                          54.166,
                          22.917
                        ],
                        [
                          31.25,
                          -25
                        ],
                        [
                          17.708,
                          -23.958
                        ],
                        [
                          17.708,
                          -37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'v': [
                        [
                          -141.771,
                          120.599
                        ],
                        [
                          -86.354,
                          90.057
                        ],
                        [
                          -33.229,
                          21.307
                        ],
                        [
                          48.021,
                          -3.693
                        ],
                        [
                          60.281,
                          -83.099
                        ],
                        [
                          141.771,
                          -118.996
                        ]
                      ],
                      'c': false
                    },
                    'ix': 2
                  },
                  'nm': 'Path 1',
                  'mn': 'ADBE Vector Shape - Group',
                  'hd': false
                },
                {
                  'ty': 'st',
                  'c': {
                    'a': 0,
                    'k': [
                      0.447058826685,
                      0.96862745285,
                      0.32549020648,
                      1
                    ],
                    'ix': 3
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 4
                  },
                  'w': {
                    'a': 0,
                    'k': 8,
                    'ix': 5
                  },
                  'lc': 1,
                  'lj': 1,
                  'ml': 10,
                  'ml2': {
                    'a': 0,
                    'k': 10,
                    'ix': 8
                  },
                  'nm': 'Stroke 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  'hd': false
                },
                {
                  'ty': 'fl',
                  'c': {
                    'a': 0,
                    'k': [
                      1,
                      1,
                      1,
                      1
                    ],
                    'ix': 4
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 5
                  },
                  'r': 1,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  'hd': false
                },
                {
                  'ty': 'tr',
                  'p': {
                    'a': 0,
                    'k': [
                      788.771,
                      279.401
                    ],
                    'ix': 2
                  },
                  'a': {
                    'a': 0,
                    'k': [
                      0,
                      0
                    ],
                    'ix': 1
                  },
                  's': {
                    'a': 0,
                    'k': [
                      100,
                      100
                    ],
                    'ix': 3
                  },
                  'r': {
                    'a': 0,
                    'k': 0,
                    'ix': 6
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 7
                  },
                  'sk': {
                    'a': 0,
                    'k': 0,
                    'ix': 4
                  },
                  'sa': {
                    'a': 0,
                    'k': 0,
                    'ix': 5
                  },
                  'nm': 'Transform'
                }
              ],
              'nm': 'Group 1',
              'np': 3,
              'cix': 2,
              'ix': 1,
              'mn': 'ADBE Vector Group',
              'hd': false
            },
            {
              'ty': 'tm',
              's': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 13,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 30.0000012219251
                  }
                ],
                'ix': 1
              },
              'e': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 0,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 30.0000012219251
                  }
                ],
                'ix': 2
              },
              'o': {
                'a': 0,
                'k': 0,
                'ix': 3
              },
              'm': 1,
              'ix': 2,
              'nm': 'Trim Paths 1',
              'mn': 'ADBE Vector Filter - Trim',
              'hd': false
            }
          ],
          'ip': 0,
          'op': 300.00001221925,
          'st': 0,
          'bm': 0
        },
        {
          'ddd': 0,
          'ind': 9,
          'ty': 4,
          'nm': 'twiggle Outlines 2',
          'sr': 1,
          'ks': {
            'o': {
              'a': 0,
              'k': 100,
              'ix': 11
            },
            'r': {
              'a': 0,
              'k': 144,
              'ix': 10
            },
            'p': {
              'a': 0,
              'k': [
                640,
                400,
                0
              ],
              'ix': 2
            },
            'a': {
              'a': 0,
              'k': [
                640,
                400,
                0
              ],
              'ix': 1
            },
            's': {
              'a': 0,
              'k': [
                74.124,
                74.124,
                100
              ],
              'ix': 6
            }
          },
          'ao': 0,
          'shapes': [
            {
              'ty': 'gr',
              'it': [
                {
                  'ind': 0,
                  'ty': 'sh',
                  'ix': 1,
                  'ks': {
                    'a': 0,
                    'k': {
                      'i': [
                        [
                          0,
                          0
                        ],
                        [
                          -54.167,
                          -22.916
                        ],
                        [
                          -31.25,
                          25
                        ],
                        [
                          -17.709,
                          23.959
                        ],
                        [
                          -17.709,
                          37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'o': [
                        [
                          0,
                          0
                        ],
                        [
                          54.166,
                          22.917
                        ],
                        [
                          31.25,
                          -25
                        ],
                        [
                          17.708,
                          -23.958
                        ],
                        [
                          17.708,
                          -37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'v': [
                        [
                          -141.771,
                          120.599
                        ],
                        [
                          -86.354,
                          90.057
                        ],
                        [
                          -33.229,
                          21.307
                        ],
                        [
                          48.021,
                          -3.693
                        ],
                        [
                          60.281,
                          -83.099
                        ],
                        [
                          141.771,
                          -118.996
                        ]
                      ],
                      'c': false
                    },
                    'ix': 2
                  },
                  'nm': 'Path 1',
                  'mn': 'ADBE Vector Shape - Group',
                  'hd': false
                },
                {
                  'ty': 'st',
                  'c': {
                    'a': 0,
                    'k': [
                      0.145098039216,
                      0.776470588235,
                      0.964705882353,
                      1
                    ],
                    'ix': 3
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 4
                  },
                  'w': {
                    'a': 0,
                    'k': 8,
                    'ix': 5
                  },
                  'lc': 1,
                  'lj': 1,
                  'ml': 10,
                  'ml2': {
                    'a': 0,
                    'k': 10,
                    'ix': 8
                  },
                  'nm': 'Stroke 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  'hd': false
                },
                {
                  'ty': 'fl',
                  'c': {
                    'a': 0,
                    'k': [
                      1,
                      1,
                      1,
                      1
                    ],
                    'ix': 4
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 5
                  },
                  'r': 1,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  'hd': false
                },
                {
                  'ty': 'tr',
                  'p': {
                    'a': 0,
                    'k': [
                      788.771,
                      279.401
                    ],
                    'ix': 2
                  },
                  'a': {
                    'a': 0,
                    'k': [
                      0,
                      0
                    ],
                    'ix': 1
                  },
                  's': {
                    'a': 0,
                    'k': [
                      100,
                      100
                    ],
                    'ix': 3
                  },
                  'r': {
                    'a': 0,
                    'k': 0,
                    'ix': 6
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 7
                  },
                  'sk': {
                    'a': 0,
                    'k': 0,
                    'ix': 4
                  },
                  'sa': {
                    'a': 0,
                    'k': 0,
                    'ix': 5
                  },
                  'nm': 'Transform'
                }
              ],
              'nm': 'Group 1',
              'np': 3,
              'cix': 2,
              'ix': 1,
              'mn': 'ADBE Vector Group',
              'hd': false
            },
            {
              'ty': 'tm',
              's': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 13,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 30.0000012219251
                  }
                ],
                'ix': 1
              },
              'e': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 0,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 30.0000012219251
                  }
                ],
                'ix': 2
              },
              'o': {
                'a': 0,
                'k': 0,
                'ix': 3
              },
              'm': 1,
              'ix': 2,
              'nm': 'Trim Paths 1',
              'mn': 'ADBE Vector Filter - Trim',
              'hd': false
            }
          ],
          'ip': 0,
          'op': 300.00001221925,
          'st': 0,
          'bm': 0
        },
        {
          'ddd': 0,
          'ind': 10,
          'ty': 4,
          'nm': 'twiggle Outlines',
          'sr': 1,
          'ks': {
            'o': {
              'a': 0,
              'k': 100,
              'ix': 11
            },
            'r': {
              'a': 0,
              'k': 72,
              'ix': 10
            },
            'p': {
              'a': 0,
              'k': [
                640,
                400,
                0
              ],
              'ix': 2
            },
            'a': {
              'a': 0,
              'k': [
                640,
                400,
                0
              ],
              'ix': 1
            },
            's': {
              'a': 0,
              'k': [
                74.124,
                74.124,
                100
              ],
              'ix': 6
            }
          },
          'ao': 0,
          'shapes': [
            {
              'ty': 'gr',
              'it': [
                {
                  'ind': 0,
                  'ty': 'sh',
                  'ix': 1,
                  'ks': {
                    'a': 0,
                    'k': {
                      'i': [
                        [
                          0,
                          0
                        ],
                        [
                          -54.167,
                          -22.916
                        ],
                        [
                          -31.25,
                          25
                        ],
                        [
                          -17.709,
                          23.959
                        ],
                        [
                          -17.709,
                          37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'o': [
                        [
                          0,
                          0
                        ],
                        [
                          54.166,
                          22.917
                        ],
                        [
                          31.25,
                          -25
                        ],
                        [
                          17.708,
                          -23.958
                        ],
                        [
                          17.708,
                          -37.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      'v': [
                        [
                          -141.771,
                          120.599
                        ],
                        [
                          -86.354,
                          90.057
                        ],
                        [
                          -33.229,
                          21.307
                        ],
                        [
                          48.021,
                          -3.693
                        ],
                        [
                          60.281,
                          -83.099
                        ],
                        [
                          141.771,
                          -118.996
                        ]
                      ],
                      'c': false
                    },
                    'ix': 2
                  },
                  'nm': 'Path 1',
                  'mn': 'ADBE Vector Shape - Group',
                  'hd': false
                },
                {
                  'ty': 'st',
                  'c': {
                    'a': 0,
                    'k': [
                      0.960784316063,
                      0.96862745285,
                      0.439215689898,
                      1
                    ],
                    'ix': 3
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 4
                  },
                  'w': {
                    'a': 0,
                    'k': 8,
                    'ix': 5
                  },
                  'lc': 1,
                  'lj': 1,
                  'ml': 10,
                  'ml2': {
                    'a': 0,
                    'k': 10,
                    'ix': 8
                  },
                  'nm': 'Stroke 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  'hd': false
                },
                {
                  'ty': 'fl',
                  'c': {
                    'a': 0,
                    'k': [
                      1,
                      1,
                      1,
                      1
                    ],
                    'ix': 4
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 5
                  },
                  'r': 1,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  'hd': false
                },
                {
                  'ty': 'tr',
                  'p': {
                    'a': 0,
                    'k': [
                      788.771,
                      279.401
                    ],
                    'ix': 2
                  },
                  'a': {
                    'a': 0,
                    'k': [
                      0,
                      0
                    ],
                    'ix': 1
                  },
                  's': {
                    'a': 0,
                    'k': [
                      100,
                      100
                    ],
                    'ix': 3
                  },
                  'r': {
                    'a': 0,
                    'k': 0,
                    'ix': 6
                  },
                  'o': {
                    'a': 0,
                    'k': 100,
                    'ix': 7
                  },
                  'sk': {
                    'a': 0,
                    'k': 0,
                    'ix': 4
                  },
                  'sa': {
                    'a': 0,
                    'k': 0,
                    'ix': 5
                  },
                  'nm': 'Transform'
                }
              ],
              'nm': 'Group 1',
              'np': 3,
              'cix': 2,
              'ix': 1,
              'mn': 'ADBE Vector Group',
              'hd': false
            },
            {
              'ty': 'tm',
              's': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 13,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 30.0000012219251
                  }
                ],
                'ix': 1
              },
              'e': {
                'a': 1,
                'k': [
                  {
                    'i': {
                      'x': [
                        0.833
                      ],
                      'y': [
                        0.833
                      ]
                    },
                    'o': {
                      'x': [
                        0.167
                      ],
                      'y': [
                        0.167
                      ]
                    },
                    'n': [
                      '0p833_0p833_0p167_0p167'
                    ],
                    't': 0,
                    's': [
                      0
                    ],
                    'e': [
                      100
                    ]
                  },
                  {
                    't': 30.0000012219251
                  }
                ],
                'ix': 2
              },
              'o': {
                'a': 0,
                'k': 0,
                'ix': 3
              },
              'm': 1,
              'ix': 2,
              'nm': 'Trim Paths 1',
              'mn': 'ADBE Vector Filter - Trim',
              'hd': false
            }
          ],
          'ip': 0,
          'op': 300.00001221925,
          'st': 0,
          'bm': 0
        }
      ]
    }
  ],
  'layers': [
    {
      'ddd': 0,
      'ind': 2,
      'ty': 0,
      'nm': 'layer 1',
      'refId': 'comp_0',
      'sr': 1,
      'ks': {
        'o': {
          'a': 0,
          'k': 100,
          'ix': 11
        },
        'r': {
          'a': 0,
          'k': 0,
          'ix': 10
        },
        'p': {
          'a': 0,
          'k': [
            660,
            420,
            0
          ],
          'ix': 2
        },
        'a': {
          'a': 0,
          'k': [
            640,
            400,
            0
          ],
          'ix': 1
        },
        's': {
          'a': 0,
          'k': [
            67.505,
            67.505,
            100
          ],
          'ix': 6
        }
      },
      'ao': 0,
      'w': 1280,
      'h': 800,
      'ip': 0,
      'op': 300.00001221925,
      'st': 0,
      'bm': 0
    },
    {
      'ddd': 0,
      'ind': 3,
      'ty': 0,
      'nm': 'layer 1',
      'refId': 'comp_0',
      'sr': 1,
      'ks': {
        'o': {
          'a': 0,
          'k': 100,
          'ix': 11
        },
        'r': {
          'a': 0,
          'k': 215,
          'ix': 10
        },
        'p': {
          'a': 0,
          'k': [
            660,
            420,
            0
          ],
          'ix': 2
        },
        'a': {
          'a': 0,
          'k': [
            640,
            400,
            0
          ],
          'ix': 1
        },
        's': {
          'a': 0,
          'k': [
            67.505,
            67.505,
            100
          ],
          'ix': 6
        }
      },
      'ao': 0,
      'w': 1280,
      'h': 800,
      'ip': 4.00000016292334,
      'op': 304.000012382174,
      'st': 4.00000016292334,
      'bm': 0
    },
    {
      'ddd': 0,
      'ind': 4,
      'ty': 0,
      'nm': 'layer 1',
      'refId': 'comp_0',
      'sr': 1,
      'ks': {
        'o': {
          'a': 0,
          'k': 100,
          'ix': 11
        },
        'r': {
          'a': 0,
          'k': 58,
          'ix': 10
        },
        'p': {
          'a': 0,
          'k': [
            660,
            420,
            0
          ],
          'ix': 2
        },
        'a': {
          'a': 0,
          'k': [
            640,
            400,
            0
          ],
          'ix': 1
        },
        's': {
          'a': 0,
          'k': [
            82.5,
            82.5,
            100
          ],
          'ix': 6
        }
      },
      'ao': 0,
      'w': 1280,
      'h': 800,
      'ip': 9.00000036657752,
      'op': 309.000012585828,
      'st': 9.00000036657752,
      'bm': 0
    },
    {
      'ddd': 0,
      'ind': 6,
      'ty': 0,
      'nm': 'Twiggles',
      'refId': 'comp_1',
      'sr': 1,
      'ks': {
        'o': {
          'a': 0,
          'k': 100,
          'ix': 11
        },
        'r': {
          'a': 0,
          'k': 0,
          'ix': 10
        },
        'p': {
          'a': 0,
          'k': [
            660,
            420,
            0
          ],
          'ix': 2
        },
        'a': {
          'a': 0,
          'k': [
            640,
            400,
            0
          ],
          'ix': 1
        },
        's': {
          'a': 0,
          'k': [
            67.505,
            67.505,
            100
          ],
          'ix': 6
        }
      },
      'ao': 0,
      'w': 1280,
      'h': 800,
      'ip': 6.00000024438501,
      'op': 306.000012463636,
      'st': 6.00000024438501,
      'bm': 0
    }
  ],
  'markers': [

  ]
}
export default data
